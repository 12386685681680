<template>
  <div class="appOrder-td" :style="style">
    <!-- <div class="appOrder-cell-block"> -->
    <slot name="default"></slot>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    style() {
      const align = {
        center: "center",
        left: "flex-start",
        right: "flex-end",
      };
      return {
        "max-width": this.width ? this.width + "px" : "auto",
        "min-width": this.minWidth ? this.minWidth + "px" : "fill-available",
        "align-items": align[this.align],
      };
    },
  },
  props: {
    width: [Number, String],
    minWidth: [Number, String],
    align: {
      default: "left",
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder-td {
  // margin-bottom: 20px;
  padding: 0 16px;
  font-size: 14px;
  min-height: 102px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid color.$background;
  border-bottom: 1px solid color.$background;
  // flex: 1 0 auto;
  // width: 0;
  flex-grow: 1;

  &:first-of-type {
    border-left: 1px solid color.$background;
  }

  // &-block {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }
}
</style>