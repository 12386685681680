<template>
  <slot v-if="hasAuth" name="default"></slot>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState("app", ["nodeList"]),
    /**
     * 是否拥有权限
     */
    hasAuth() {
      return this.nodeList.some((item) => {
        return item.path == this.name;
      });
    },
  },
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>