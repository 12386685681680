<template>
  <div class="app-menu-primary" v-if="menu">
    <template v-if="menu.children?.length">
      <div
        class="app-menu-primary-section"
        :class="{ 'app-menu-primary-section_active': active }"
        @mouseenter="$emit('second', menu.children)"
      >
        <app-icon class="app-menu-primary-icon" :name="menu.icon" />
      </div>
      <!-- 
      <teleport to="body">
        <transition name="fade" mode="out-in">
          <div
            class="app-menu-primary-second"
            v-show="secondShow"
            @mouseenter="secondShowChange(true)"
            @mouseleave="secondShowChange(false)"
          >
            <app-menu-second
              v-for="(item, index) in menu.children"
              :key="index"
              :menu="item"
              :level="0"
            ></app-menu-second>
          </div>
        </transition>
      </teleport> -->
    </template>
    <div
      class="app-menu-primary-section"
      :class="{ 'app-menu-primary-section_active': active }"
      @click="$emit('toNav', menu)"
      @mouseenter="$emit('second', [])"
      v-else
    >
      <app-icon class="app-menu-primary-icon" :name="menu.icon" />
    </div>
  </div>
</template>

<script>
// import appMenuSecond from "./app-menu-second";
export default {
  emits: ["toNav", "second"],
  components: {
    // appMenuSecond,
  },
  props: {
    menu: Object,
  },
  computed: {
    // active() {
    //   console.log(this.menu);
    //   console.log(this.$route.name)
    //   return this.find(this.menu).includes(this.$route.name);
    // },
  },
  watch: {
    menu: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive();
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive();
      },
    },
  },
  data() {
    return {
      secondShow: false,
      secondFlag: false,
      active: false,
    };
  },
  methods: {
    secondShowChange(status) {
      if (status == true) {
        this.secondShow = true;
        this.secondFlag = true;
      } else {
        this.secondFlag = false;
        setTimeout(() => {
          if (this.secondFlag == false) {
            this.secondShow = false;
          }
        }, 500);
      }
    },
    /**
     * 设置激活
     */
    setActive() {
      this.active = this.find(this.menu).includes(this.$route.name);
      // if (this.active) this.secondShow = true;
    },

    /**
     * 查找当前路由是否存在于menu中
     */
    find(menu, result = []) {
      if (menu.children?.length) {
        menu.children.forEach((item) => {
          this.find(item, result);
        });
      } else {
        result.push(menu.path);
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-menu-primary {
  position: relative;

  &-section {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
    padding: 0 20px;
    border-bottom: 1px solid #212533;
    height: 56px;

    // 激活
    &_active {
      background-color: color.$base;
    }

    &:hover::before {
      pointer-events: none;
      opacity: 0.1;
      content: "";
      background-color: #fff;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    color: #fff;
    text-decoration: none;
  }

  &-second {
    background-color: #212533;
    position: fixed;
    top: 0;
    left: 56px;
    width: 250px;
    height: 100vh;
    z-index: 11;
  }
}
</style>
