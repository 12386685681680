<template>
  <div class="appOrder-cell">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  props: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder-cell {
  margin: 0 -16px;
  padding: 16px;
  width: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid color.$background;
  }
}
</style>