<template>
  <div class="appOrder-operation">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder-operation {
  // padding: 0 16px;
  font-size: 14px;
  // height: calc(102px + 42px);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 20px;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid color.$background;
  border-bottom: 1px solid color.$background;
  // position: relative;
  padding-top: 42px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    display: flex;
    height: 42px;
    background-color: color.$background;
  }
}
</style>