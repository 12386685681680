<template>
  <div class="app-menu-second">
    <template v-if="menu.children?.length">
      <div
          class="app-menu-second-section"
          :class="{ 'app-menu-second-section_active': active }"
          @click="secondShow = !secondShow"
      >
        <app-icon
            class="app-menu-second-icon"
            :name="menu.icon"
            v-if="menu.icon"
        />
        <div class="app-menu-second-title"
             :class="{ 'app-menu-second-title_active': active }"
        >
          {{ menu.name }}
        </div>
      </div>
      <el-collapse-transition>
        <div class="app-menu-second-next" v-if="secondShow">
          <app-menu-second
              v-for="(item, index) in menu.children"
              :key="index"
              :menu="item"
              :level="level + 1"
              @toNav="$emit('toNav', $event)"
          ></app-menu-second>
        </div>
      </el-collapse-transition>
    </template>
    <div
        class="app-menu-second-section"
        :class="{
        'app-menu-second-section_active': active && level == 0,
        'app-menu-thirdly-section_active': active && level >= 1,
      }"
        @click="$emit('toNav', menu)"
        v-else
    >
      <app-icon
          class="app-menu-second-icon"
          :name="menu.icon"
          v-if="menu.icon"
      />
      <div class="app-menu-second-title"
           :class="{ 'app-menu-second-title_active': active }"
      >
        {{ menu.name }}
      </div>
    </div>
  </div>
</template>

<script>
import appMenuSecond from "./app-menu-second";
export default {
  emits: ["toNav"],
  components: {
    appMenuSecond,
  },
  props: {
    menu: Object,
    level: Number,
  },
  watch: {
    menu: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive();
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive();
      },
    },
  },
  data() {
    return {
      secondShow: false,
      active: false,
    };
  },
  methods: {
    /**
     * 设置激活
     */
    setActive() {
      this.active = this.find(this.menu).includes(this.$route.name);
      if (this.active) this.secondShow = true;
    },

    /**
     * 查找当前路由是否存在于menu中
     */
    find(menu, result = []) {
      if (menu.children?.length) {
        menu.children.forEach((item) => {
          this.find(item, result);
        });
      } else {
        result.push(menu.path);
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-menu-second {
  //background-color: #212533;
  background: #FFFFFF;

  &-section {
    padding-left: calc(v-bind(level) * 20px + 50px);
    padding-right: 20px;
    height: 56px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    //color: #fff;
    color: #333333;
    text-decoration: none;

    &:hover::before {
      pointer-events: none;
      opacity: 0.1;
      content: "";
      background-color: #fff;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    // 激活
    &_active{
      background: #DBE9FD;
    }
    &_active::after {
      width: 4px;
      height: 100%;
      background: color.$base;
      content: "";
      display: block;
      position: absolute;
      right: calc(v-bind(level) * 20px);
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &-title {
    height: 56px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #333333;
    text-decoration: none;

    &_active{
      color: #4286ED;
    }
  }
}

.app-menu-thirdly-section {
  // 激活
  &_active::after {
    width: 6px;
    height: 6px;
    background: color.$base;
    border-radius: 50%;

    content: "";
    display: block;
    position: absolute;
    right: 22px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
