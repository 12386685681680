<template>
  <div class="appSlide">
    <div
      class="appSlide-content"
      :class="{
        'appSlide-content-show': show,
      }"
    >
      <div ref="appSlide-content-entity" class="appSlide-content-entity">
        <slot name="default"></slot>
      </div>
    </div>
    <div class="appSlide-button" @click="slideToggle()" v-if="needSlide">
      <div class="appSlide-button-text">
        <div
          class="appSlide-button-text-element"
          :class="{ 'appSlide-button-text-show': show }"
        >
          隐藏
        </div>
        <div
          class="appSlide-button-text-element"
          :class="{ 'appSlide-button-text-show': show }"
        >
          展开
        </div>
      </div>
      <div class="appSlide-button-icon">
        <div
          class="appSlide-button-icon-element"
          :class="{ 'appSlide-button-icon-element-show': show }"
        >
          <app-icon
            class="appSlide-button-icon-element-entity"
            size="12px"
            name="add"
          />
        </div>
        <div
          class="appSlide-button-icon-element"
          :class="{ 'appSlide-button-icon-element-show': !show }"
        >
          <app-icon
            class="appSlide-button-icon-element-entity"
            size="12px"
            name="subtract"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    needSlide() {
      return !(this.scrollHeight == this.height);
    },
  },
  props: {
    height: {
      type: String,
      default: "40px"
    },
  },
  data() {
    return {
      show: false,
      scrollHeight: 0,
    };
  },
  mounted() {
    this.init();
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    init() {
      this.resize();
      window.addEventListener("resize", this.resize);
    },
    slideToggle() {
      this.show = !this.show;
    },
    resize() {
      this.scrollHeight = `${this.$refs["appSlide-content-entity"].offsetHeight}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.appSlide {
  display: flex;
  flex: 1;

  &-content {
    overflow: hidden;
    flex: 1;
    height: v-bind(height);
    transition: 250ms;

    &-show {
      overflow: initial;
      height: v-bind(scrollHeight);
    }
  }

  &-button {
    font-size: 14px;
    height: v-bind(height);
    color: color.$subText;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &-text {
      height: 20px;
      line-height: 20px;
      overflow: hidden;

      &-element {
        transform: translateY(-100%);
        transition: 250ms;
      }

      &-show {
        transform: translateY(0);
      }
    }

    &-icon {
      width: 20px;
      height: 20px;
      background-color: color.$background;
      border-radius: 50%;
      position: relative;
      transform: translate3d(0, 0, 0);
      margin-left: 10px;

      &-element {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotateX(0);
        transition: 250ms;
        overflow: hidden;

        &-entity {
          color: color.$baseText;
        }

        &-show {
          transform: rotateX(90deg);
        }
      }
    }
  }
}
</style>