import { createApp } from 'vue'
import app from './app.vue'
import router from './router'
import store from './store'
import request from './request';
import utils from './utils';
import "./utils/gt";
import "default-passive-events"
import dayjs from 'dayjs';
import mixin from './mixin';
import VueUeditorWrap from 'vue-ueditor-wrap';
import appIcon from '@/components/app-icon'
import appStatus from '@/components/app-status'
import appDatePicker from '@/components/app-date-picker'
import appPageHeader from '@/components/app-page-header'
import appHeaderExtend from '@/components/app-header-extend'
import appDescriptions from "@/components/app-descriptions";
import appDescriptionsItem from "@/components/app-descriptions-item";
import appExtra from "@/components/app-extra";
import appExtraItem from "@/components/app-extra-item";
import appDialog from "@/components/app-dialog";
import appDialog2 from "@/components/app-dialog2";
import appOrder from "@/components/app-order";
import appOrderTh from "@/components/app-order-th";
import appOrderTd from "@/components/app-order-td";
import appOrderCell from "@/components/app-order-cell";
import appOrderLabel from "@/components/app-order-label";
import appOrderOperation from "@/components/app-order-operation";
import appTableOperation from "@/components/app-table-operation";
import appTableOperationLabel from "@/components/app-table-operation-label";
import appResult from "@/components/app-result";
import appOrderLog from "@/components/app-order-log";
import appAuth from "@/components/app-auth";
import appSlide from "@/components/app-slide";
import appPicture from "@/components/app-picture";
import appSelScenic from "@/components/app-sel-scenic";
import appUploadfile from '@/components/app-uploadfile/index.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { ClickOutside } from "element-plus";
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 设置时间组件时区
dayjs.locale("zh-cn");

// 创建项目
const youcai = createApp(app)

// 挂载组件
youcai
    .use(store)
    .use(router)
    .use(ElementPlus, {
        locale: zhCn,
        size: 'large'
    })
    .use(VueUeditorWrap)
    .component('app-uploadfile', appUploadfile)
    .component('app-icon', appIcon)
    .component('app-status', appStatus)
    .component('app-page-header', appPageHeader)
    .component('app-header-extend', appHeaderExtend)
    .component('app-descriptions', appDescriptions)
    .component('app-descriptions-item', appDescriptionsItem)
    .component('app-dialog', appDialog)
    .component('app-dialog2', appDialog2)
    .component('app-date-picker', appDatePicker)
    .component('app-order', appOrder)
    .component('app-order-th', appOrderTh)
    .component('app-order-td', appOrderTd)
    .component('app-order-cell', appOrderCell)
    .component('app-order-label', appOrderLabel)
    .component('app-order-operation', appOrderOperation)
    .component('app-table-operation', appTableOperation)
    .component('app-table-operation-label', appTableOperationLabel)
    .component('app-result', appResult)
    .component('app-extra', appExtra)
    .component('app-extra-item', appExtraItem)
    .component('app-order-log', appOrderLog)
    .component('app-auth', appAuth)
    .component('app-slide', appSlide)
    .component('app-picture', appPicture)
    .component('app-sel-scenic', appSelScenic)
    .mixin(mixin)
    .mount('#app')


// 设置全局请求
youcai.config.globalProperties.$request = request;
youcai.config.globalProperties.$moment = dayjs;
youcai.config.globalProperties.$utils = utils;
youcai.directive('ClickOutside', ClickOutside)
