<template>
  <el-table
    :data="data"
    class="appOrder"
    ref="appOrder"
    :span-method="setSpan"
  >
    <el-table-column width="1">
      <template #default="props">
        <div class="appOrder-header">
          <slot name="header" :row="props.row"></slot>
        </div>
        <div class="appOrder-body">
          <slot name="body" :row="props.row"></slot>
        </div>
      </template>
    </el-table-column>
    <slot name="column"></slot>
  </el-table>
</template>

<script>
export default {
  components: {},
  props: {
    data: Array,
    span: Number,
  },
  data() {
    return {};
  },
  mounted() {
    // 获取数据
    // this.getData();
  },
  methods: {
    /**
     * 设置span
     */
    setSpan(span) {
      if (span.columnIndex == 0) {
        return [1, this.span];
      } else if (span.columnIndex == this.span) {
        return [1, 1];
      } else {
        return [0, 0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 订单
.appOrder {
  flex: 1;

  ::v-deep(.el-table__header-wrapper) {
    border-radius: 4px 4px 0 0;
  }

  ::v-deep(th.el-table__cell) {
    background-color: color.$base !important;
    color: #fff;
    border-bottom: none;
  }

  ::v-deep(tr td.el-table-fixed-column--right.is-first-column::before) {
    bottom: 20px;
  }

  // ::v-deep(tr td.el-table-fixed-column--right.is-first-column::after) {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   content: "";
  //   display: block;
  //   display: flex;
  //   height: 42px;
  //   background-color: #f2f1f6;
  // }

  ::v-deep(tr td.el-table-fixed-column--right.is-first-column) {
    // padding: 0 16px;
    // font-size: 14px;
    // // height: calc(102px + 42px);
    // // align-self: stretch;
    // border-right: 1px solid color.$background;
    // border-bottom: 1px solid color.$background;
    // position: relative;
    // padding-top: 42px;
    position: relative;
  }

  ::v-deep(td.el-table__cell) {
    padding: 0;
    border-bottom: none;
  }

  ::v-deep(td.el-table__cell .cell) {
    padding: 0;
  }

  ::v-deep(tr.hover-row td.el-table__cell) {
    background-color: #fff;
  }

  &-body {
    display: flex;
  }

  &-header {
    display: flex;
    gap: 20px;
    height: 42px;
    line-height: 42px;
    padding: 0 28px;
    background-color: color.$background;
  }
}
</style>