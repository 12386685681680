<template>
  <el-col :span="span" class="app-extra-item">
    <slot></slot>
  </el-col>
</template>

<script>
export default {
  components: {},
  props: {
    span: {
      default: 24,
      type: Number,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-extra-item {
  display: flex !important;
  gap: 14px;
  align-items: center;
  margin-bottom: 20px;

  ::v-deep(.el-form-item) {
    width: stretch;
  }

  ::v-deep(.el-row) {
    width: stretch;
  }
}
</style>