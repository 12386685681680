<template>
  <div class="appPageHeader" v-if="title">
    <div class="appPageHeader-title">
      {{ title }}
    </div>
    <div class="appPageHeader-extend">
      <slot name="extend"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-page-header",
  computed: {},
  props: {
    title: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// 顶部
.appPageHeader {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid color.$border;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: #FFF;

  // 标题
  &-title {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    font-family: Source Han Sans CN;
    color: color.$base;
    text-decoration: none;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: default;

    &::after {
      display: block;
      content: "";
      height: 4px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background-color: color.$base;
    }
  }

  &-extend {
    margin: 0 10px;
  }
}
</style>