<template>
  <el-dialog
    custom-class="appDialog"
    destroy-on-close
    v-model="dialogValue"
    :title="title"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    width: {
      default: 400,
      type: Number,
    },
    modelValue: Boolean,
  },
  watch: {
    modelValue(modelValue) {
      this.dialogValue = modelValue;
    },
    dialogValue(dialogValue) {
      this.$emit("update:modelValue", dialogValue);
    },
  },
  data() {
    return {
      dialogValue: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// 弹框
.appDialog {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;

  .appPage {
    padding: 0 20px;
  }

  .appTable-footer {
    padding-top: 0;
    padding-bottom: 0;
    height: 100px;
  }

  .el-dialog__header {
    border-bottom: 1px solid color.$border;
    margin-right: 0;
    padding: 0 24px;
    height: 65px;
    display: flex;
    align-items: center;
  }

  .el-dialog__title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: color.$base;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 10px;

    &::after {
      content: "";
      display: block;
      height: 4px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: #6663ff;
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__close {
    border-radius: 50%;
    border: 2px solid color.$base;
    color: color.$base;
    width: 24px;
    height: 24px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>