<template>
  <div class="appContent">
    <!-- 面包导航 -->
    <!-- <div class="appContent-breadcrumb">
      <div
        class="appContent-breadcrumb-item"
        v-for="item in breadcrumb"
        :key="item.path"
      >
        {{ item.name }}
      </div>
    </div> -->
    <!-- 页面容器 -->
    <div class="appContent-container">
      <!-- 页面标题 -->
<!--      <app-page-header-->
<!--        :title="$route.meta.title"-->
<!--        v-if="$route.meta.auth.header"-->
<!--      >-->
<!--        <template #extend>-->
<!--          <div id="appContent-header"></div>-->
<!--        </template>-->
<!--      </app-page-header>-->

      <!-- 页面内容 -->
      <div class="appContent-body">
        <el-scrollbar ref="scroll" class="appContent-body-scroll">
          <router-view></router-view>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "appContent",
  watch: {
    async $route() {
      await this.$nextTick();
      if (this.$refs.scroll) this.$refs.scroll.update();
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appContent {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  // 面包导航
  &-breadcrumb {
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    padding-top: 15px;

    &-item {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #909399;

      &:not(:last-of-type)::after {
        content: "/";
        margin: 0 8px;
      }
    }
  }

  // 容器
  &-container {
    margin: 15px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
  }

  // 顶部
  &-header {
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid color.$border;

    // 标题
    &-title {
      margin: 0 10px;
      font-size: 18px;
      font-weight: bold;
      font-family: Source Han Sans CN;
      color: color.$base;
      text-decoration: none;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: default;

      &::after {
        display: block;
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: color.$base;
      }
    }
  }

  // 中部
  &-body {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;

    &-scroll {
      flex: 1;
      height: 0;
      ::v-deep(.el-scrollbar__view) {
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.appStatus {
  flex: 1;
  height: 0;
}
</style>