<template>
    <div class="image" @mouseenter="operationShow" @mouseleave="operationHide">
        <el-image fit="cover" :src="src" class="image-img" :preview-src-list="[src]">
            <template #error>
                <div class="default-img">
                    <img src="@/assets/default.png" alt="">
                </div>
            </template>
        </el-image>

        <transition name="el-fade-in-linear">
            <div class="image-loading" v-if="loading.status">
                {{ loading.progress }}%
            </div>
        </transition>

        <transition name="el-fade-in-linear">
            <div class="image-operation" v-if="operation.status && !disabled">
                <label class="image-operation-item">
                    <app-icon name="edit"/>
                    <form ref="clearTool">
                        <input type="file" @change="edit($event, index)"/>
                    </form>
                </label>
                <div class="image-operation-item" @click="$emit('del')">
                    <app-icon name="close"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    computed: {},
    props   : {
        /**
         * @type {"default"|"oss"}
         * */
        uploadType: String,
        disabled  : Boolean,
        modelValue: Object,
    },
    data() {
        return {
            src      : "",
            loading  : {
                progress: 0,
                status  : false,
            },
            operation: {
                status: false,
            },
            api      : process.env.VUE_APP_API_URL
        };
    },
    watch: {
        modelValue: {
            immediate: true,
            async handler(modelValue) {
                if (modelValue.image && !modelValue.url) this.add(modelValue.image);
                else if (!modelValue.image && modelValue.url) this.src = modelValue.url;
            },
        },
        // image: {
        //   immediate: true,
        //   async handler(image) {
        //     if (image) this.add(image);
        //   },
        // },
        // url: {
        //   immediate: true,
        //   async handler(url) {
        //     if (!this.image && url) this.src = url;
        //   },
        // },
    },
    mounted() {
        if (this.src.slice(0, 4) != 'http') {
            this.src = this.api + this.src
        }
    },
    methods: {
        /**
         * 添加图片
         */
        async add(image) {
            // 修改列表
            this.src = await this.reader(image);
            //
            // this.$emit("update:modelValue", {
            //   image,
            // });

            await this.upload(image);


        },
        /**
         * 修改图片
         */
        async edit(e) {
            // 获取图片
            const [image] = e.target.files;

            // 修改列表
            this.src = await this.reader(image);

            //
            this.$emit("update:image", image);

            // 上传图片
            this.upload(image);

            // 清空
            this.$refs.clearTool.reset();
        },
        /**
         * 文件渲染辅助函数
         */
        reader(file) {
            return new Promise((resolve) => {
                // 创建文件渲染工具
                const reader = new FileReader();

                //
                reader.onload = function (res) {
                    resolve(res.target.result);
                };

                //
                reader.readAsDataURL(file);
            });
        },

        /**
         *
         */
        operationHide() {
            this.operation.status = false;
        },

        operationShow() {
            if (!this.loading.status) {
                this.operation.status = true;
            }
        },

        /**
         * 上传数据
         */
        async upload(image) {
            const data = new FormData();
            data.append("image", image);

            try {
                let url = "/upload/image";
                // 显示加载
                this.loading.status = true;
                // if (this.uploadType == 'default') {
                //     url = "/upload/image"
                // } else {
                //     url = "/upload/ossImage"
                // }
                // 发送请求
                const result = await this.$request.request({
                    url             : url,
                    method          : "POST",
                    headers         : {"Content-type": "multipart/form-data"},
                    data,
                    onUploadProgress: (e) => {
                        this.loading.progress = Math.floor((e.loaded / e.total) * 100);
                    },
                });

                // // 获取数据
                this.$emit("update:modelValue", {
                    image,
                    // url: result.data.url,
                    url: result.data.storage,
                });
            } catch (e) {
                // 弹出错误
                this.$message.error(e.message);
            } finally {
                // 关闭加载
                this.loading.status = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.default-img {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.image {
  position: relative;
  width: 100%;
  height: 100%;

  &-img {
    @include fn.cover;
  }

  &-loading {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  &-operation {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 14px;

    &-item {
      color: #fff;
      cursor: pointer;

      form {
        display: none;
      }
    }
  }
}
</style>
