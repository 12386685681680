<template>
  <div class="appOrderLog">
    <div class="list" v-for="item in list" :key="item.id">
      <div class="title">{{ item.created_at + "  " + item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.appOrderLog {
  box-sizing: border-box;
  padding: 10px;
  color: color.$subText;
  border: 1px solid color.$inputText;
  width: 100%;
  .list {
    display: flex;
    .title {
      margin-left: 10px;
    }
  }
}
</style>