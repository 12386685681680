<template>
  <div class="appTable-operation">
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// 表格-操作
.appTable-operation {
  display: flex;
  color: color.$base;
  gap: 20px;

  // &-item {
  //   color: color.$tableText;
  //   cursor: pointer;
  //   position: relative;
  //   font-size: 12px;
  //   @include fn.separate;

  //   &:hover {
  //     color: color.$base;
  //   }
  // }

  // &-more &-item {
  //   text-align: center;
  //   line-height: 24px;
  //   height: 24px;

  //   &:not(:last-of-type)::after {
  //     display: none;
  //   }
  // }
}
</style>